/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers';
import { CountDown } from "./countdown.js";

const scripts = [
    { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=62a2e6ccc03c347bac421db9").then(body => body.text()), isAsync: false },
    { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class IndexViewManual extends React.Component {
    static get Controller() {
        if (Controller) return Controller

        try {
            Controller = require('../controllers/IndexController')
            Controller = Controller.default || Controller

            return Controller
        }
        catch (e) {
            if (e.code == 'MODULE_NOT_FOUND') {
                Controller = IndexViewManual

                return Controller
            }

            throw e
        }
    }

    componentDidMount() {
        const htmlEl = document.querySelector('html')
        htmlEl.dataset['wfPage'] = '62a2e6ccc03c348df6421dbe'
        htmlEl.dataset['wfSite'] = '62a2e6ccc03c347bac421db9'

        scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
            const loading = active.loading.then((script) => {
                new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

                return next
            })

            return active.isAsync ? next : loading
        }))
    }

    render() {
        const proxies = IndexViewManual.Controller !== IndexViewManual ? transformProxies(this.props.children) : {

        }

        return (
            <span>
                <style dangerouslySetInnerHTML={{
                    __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/hst2-1.webflow.css);
        ` }} />
                <span className="af-view">
                    <div>
                        <div className="af-class-main">
                            <div data-poster-url="videos/BVAT_Heist_BW-poster-00001.jpg" data-video-urls="videos/BVAT_Heist_BW-transcode.mp4,videos/BVAT_Heist_BW-transcode.webm" data-autoplay="true" data-loop="true" data-wf-ignore="true" className="af-class-background-video w-background-video w-background-video-atom"><video id="9e6bfa0e-cd69-36c1-f28d-482bf6ea0836-video" autoPlay loop style={{ backgroundImage: 'url("videos/BVAT_Heist_BW-poster-00001.jpg")' }} muted playsInline data-wf-ignore="true" data-object-fit="cover">
                                <source src="videos/BVAT_Heist_BW-transcode.mp4" data-wf-ignore="true" />
                                <source src="videos/BVAT_Heist_BW-transcode.webm" data-wf-ignore="true" />
                            </video></div>
                            <div data-w-id="87ae9be3-18e8-4e77-cc24-278d8cb0f164" className="af-class-spill-over">
                                <CountDown />
                                <div className="af-class-section-2 af-class-wf-section">
                                    <div className="af-class-div-block-8">
                                        <div className="af-class-div-block-6">
                                            <h1 className="af-class-heading-4">Follow Us</h1>
                                            <div className="af-class-div-block-5"><img src="images/discord.svg" loading="lazy" width={24} height={24} alt className="af-class-image" /></div>
                                            <div className="af-class-div-block-5"><img src="images/twitter.svg" loading="lazy" width={24} height={24} alt className="af-class-image" /></div>
                                        </div>
                                        <div className="af-class-div-block-7">
                                            <h1 className="af-class-heading-4">Quick Link</h1>
                                            <div className="af-class-div-block-5"><img src="images/Model.svg" loading="lazy" width={24} height={24} alt className="af-class-image" /></div>
                                            <div className="af-class-div-block-5"><img src="images/Frame-27.svg" loading="lazy" width={24} height={24} alt className="af-class-image" /></div>
                                            <div className="af-class-div-block-5"><img src="images/Mask-group.svg" loading="lazy" width={24} height={24} alt className="af-class-image" /></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="af-class-section-3 af-class-wf-section">
                                    <div className="af-class-div-block-9"><img src="images/BVAT-Logo.svg" loading="lazy" alt className="af-class-image-2" />
                                        <div className="af-class-div-block-10">
                                            <h1 className="af-class-heading-3">Brain in a Vat</h1>
                                            <p className="af-class-paragraph-2">Brain In a Vat (BIAV) group is dedicated to disrupting the current web3 and NFT Market. All of our ideas were inspired by the philosophical thought experiment “Brain in a Vat.”</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="af-class-cursor">
                            <div data-w-id="ccf69e4c-7685-10e7-3451-3e947cc6f257" className="af-class-cursor-ring" />
                        </div>
                        <div className="af-class-html-embed w-embed">
                            <style dangerouslySetInnerHTML={{ __html: "\n.af-view .af-class-cursor {pointer-events:none;}\n.af-view .af-class-nav-link::before {content:\"●\"; margin-right:20px;}\n.af-view .af-class-project-title {-webkit-text-stroke: 1px #ffffff;}\n" }} />
                        </div>
                        {/* [if lte IE 9]><![endif] */}
                    </div>
                </span>
            </span>
        )
    }
}

export default IndexViewManual

/* eslint-enable */